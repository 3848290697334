import Vue from 'vue'
import Router from 'vue-router'
import login from './views/Login.vue'
import dashboard from './views/DashBoard.vue'
import {auth} from './firebase'


Vue.use(Router)

const routes =  [
  {
    path:'/login',
    name:'login',
    component:login
  },
  {
    path: '/',
    name: '',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: dashboard,
    meta:{
      requiresAuth : true
    },
    children:[
      {
        path: '/',
        name: 'DashBoard',
        component: ()=>import('./views/home.vue'),
        meta:{
          requiresAuth : true
        }
      },
      {
        path: 'profile',
        name: 'Profile',
        component: ()=>import('./views/Profile.vue'),
        meta:{
          requiresAuth : true
        }
      },
      {
        path: 'addstudent',
        name: 'AddStudent',
        component: ()=>import('./views/AddStudent.vue'),
        meta:{
          requiresAuth : true
        }
      },
      {
        path: 'subjects',
        name: 'Subjects',
        component: ()=>import('./views/Subjects.vue'),
        meta:{
          requiresAuth : true
        }
      },
      {
        path: 'courses',
        name: 'Courses',
        component: ()=>import('./views/Courses.vue'),
        meta:{
          requiresAuth : true
        }
      },
      {
        path: 'signup',
        name: 'Add User',
        component: ()=>import('./views/Signup.vue'),
        meta:{
          requiresAuth : true
        }
      },
      {
        path: 'changepassword',
        name: 'Change Password',
        component: ()=>import('./views/changePassword.vue'),
        meta:{
          requiresAuth : true
        }
      },
      {
        path: 'edit/:id',
        name: 'Edit Student',
        component: ()=>import('./views/EditStudent.vue'),
        meta:{
          requiresAuth : true
        }
      },
      {
        path: 'allstudents',
        name: 'Students',
        component: ()=>import('./views/allStudents.vue'),
        meta:{
          requiresAuth : true
        }
      },
      {
        path: 'student/:id',
        name: 'Detail',
        component: ()=>import('./views/student.vue'),
        meta:{
          requiresAuth : true
        }
      }
    ]
  },
  
]


 const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to,from,next)=>{
  const requiresAuth = to.matched.some( x => x.meta.requiresAuth)
  if(requiresAuth && !auth.currentUser){
    next('/login')
  }
  else{
    next()
  }
})

export default router