<template>
  <div class="relative md:flex min-h-screen">
    <!-- Mobile Nav -->

    <div class="bg-black text-gray-100 h-12 flex md:hidden justify-between">
      <!-- Logo -->
      <router-link to="/" class="flex space-x-2 px-4 py-2 ">
        <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 20H5a2 2 0 01-2-2V6a2 2 0 012-2h10a2 2 0 012 2v1m2 13a2 2 0 01-2-2V7m2 13a2 2 0 002-2V9a2 2 0 00-2-2h-2m-4-3H9M7 16h6M7 8h6v4H7V8z" />
      </svg> -->
        <span class="text-2xl">News Paper</span>
      </router-link>

      <!-- Menu Icon -->
      <button class="px-5 focus:outline-none" @click="show = !show">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6h16M4 12h8m-8 6h16"
          />
        </svg>
      </button>
    </div>

    <!-- SideBar -->

    <div
      class="w-72 bg-gray-100 text-black  px-4 absolute inset-y-0 left-0 transform  transition duration-200 ease-in-out md:relative md:translate-x-0">
      <!-- Logo -->
      <div href="#" class="flex space-x-2 px-4 py-5 pb-6">
        <img src="../assets/svg/book-open-solid.svg" class="w-10 h-10">
        <span class="text-xl pt-1 text-black">UDAAN Planet</span>
      </div>

     <!-- Nav List -->
      <nav>

        <router-link v-for="item in items" :key="item.name" :to="item.link" class="list">
            <i :class="[item.icon,'icon']"></i>
            <span class="text-lg px-3">{{ item.name }}</span>
        </router-link>

      </nav>
      
    </div>
    <!-- Content -->

    <div class="flex-1">
        <NavBar/>
        <div class=" md:px-16 px-7 py-7 bg-white h-min-full">
            <router-view />
        </div>
      
    </div>
  </div>
</template>

<script>
import NavBar from '../components/navbar.vue'

export default {
  name: "dashboard",
  components:{
      NavBar,

  },
  data() {
    return {
      items:[
        {
          name:'DashBoard',
          link:'/',
          icon:'fas fa-tachometer-alt',
        },
        {
          name:'Add Student',
          link:'/AddStudent',
          icon:'fas fa-user-plus'
        },
        {
          name:'All Student',
          link:'/allstudents',
          icon:'fas fa-users-class'
        },
        {
          name:'Add Subject',
          link:'/subjects',
          icon:'fas fa-books'
        },
        {
          name:'Add Course',
          link:'/courses',
          icon:'fas fa-book'
        },
      ]
    }
  },
};
</script>

<style>
.icon{
  @apply flex-none w-6 h-6 pt-1.5
}
.list{
  @apply flex rounded hover:bg-purple-600 hover:text-white transition duration-200 py-2.5 px-4 mt-2
}
</style>

