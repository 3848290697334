import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/analytics'

const firebaseConfig = {
    apiKey: "AIzaSyDMrXYY_sNDwAl5Inl-KcAATVX1E4Mr3yc",
    authDomain: "udaanplanet-8d0d4.firebaseapp.com",
    projectId: "udaanplanet-8d0d4",
    storageBucket: "udaanplanet-8d0d4.appspot.com",
    messagingSenderId: "691897389280",
    appId: "1:691897389280:web:20e13ec13b3fba84f20085",
    measurementId: "G-N6GEH9N8F5"

    // apiKey: "AIzaSyCLfleoFrUlswcBaPCuJxir6DY34dKNqvY",
    // authDomain: "udtest-6a8de.firebaseapp.com",
    // projectId: "udtest-6a8de",
    // storageBucket: "udtest-6a8de.appspot.com",
    // messagingSenderId: "658054362858",
    // appId: "1:658054362858:web:2902c6e381c0a2d8a94993"
  };

  firebase.initializeApp(firebaseConfig);
  firebase.analytics();
  //utils
  
//   const realtimeDB = firebase.database()
  const db = firebase.firestore()
  const auth = firebase.auth()
 const storage = firebase.storage()
 
  //collection reference

//   const totalStudents = realtimeDB.ref('counter')
  const userCollection = db.collection('users')
  const courseCollection = db.collection('courses')
  const subjectCollection = db.collection('subjects')
  const studentCollection = db.collection('students')

  export{
      db,
      auth,
    //   realtimeDB,
      storage,
    //   totalStudents,
      userCollection,
      courseCollection,
      subjectCollection,
      studentCollection
  }