import Vue from 'vue'
import App from './App.vue'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import store from './store'
import router from './router'
import '@/assets/css/style.css'
import  '@/assets/css/fontawesome_v5.15.1_pro.css'
import {auth} from './firebase'

Vue.use(Buefy)
Vue.config.productionTip = false

let app

auth.onAuthStateChanged(user =>{
  if(!app){
    app = new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  }
  if (user) {
    store.dispatch('fetchUserProfile',user)
  }
})



