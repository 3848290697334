<template>
    <div class="flex items-center justify-center h-screen">
        <div class="md:bg-gray-100 md:w-96 w-full h-auto pl-5 pr-5 pt-5 pb-14 rounded justify-center">

            <div class="flex justify-center pt-5 pb-14">
                <img src="../assets/svg/book-open-solid.svg" class="w-10 h-10">
                <span class="text-2xl pt-1 pl-2">UDAAN Planet</span>
            </div>

            <b-field label='Email'>
                <b-input type='Text' placeholder='@udaanplanet.in' v-model="email"/>
            </b-field>

            <b-field label='Password'>
                <b-input type='Password' placeholder='Password' icon-pack='far' v-model="password" password-reveal/>
            </b-field>

            <div class="pt-8">
                <b-button icon-pack='far'
                    icon-left="sign-in-alt" class="btn" @click="login"  expanded>
                    Sign In
                </b-button>
            </div>
            
            
        </div>
    </div>
</template>

<script>
    export default {
        name:'login',
        data() {
            return {
                email:'',
                password:''
            }
        },
        methods: {
            login(){
                this.$store.dispatch('login',{
                    email: this.email,
                    password:this.password
                })
            }
        },
    }
</script>

<style>
.btn{
        @apply bg-purple-700 text-white hover:text-white hover:bg-purple-900
    }
</style>
    
