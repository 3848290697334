import Vue from "vue";
import Vuex from "vuex";
import * as fb from "./firebase";
import router from "./router";
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    courses: [],
    subjects: [],
    students:[],
    userProfile: {},
    editUserProfile: {},
    editStudentDetail:[],
    viewStudent:[],
    total:{
      courses:0,
      subjects:0,
    },
    states: [
      "Andhra Pradesh",
      "Arunachal Pradesh",
      "Assam",
      "Bihar",
      "Chhattisgarh",
      "Goa",
      "Gujarat",
      "Haryana",
      "Himachal Pradesh",
      "Jammu and Kashmir",
      "Jharkhand",
      "Karnataka",
      "Kerala",
      "Madhya Pradesh",
      "Maharashtra",
      "Manipur",
      "Meghalaya",
      "Mizoram",
      "Nagaland",
      "Odisha",
      "Punjab",
      "Rajasthan",
      "Sikkim",
      "Tamil Nadu",
      "Telangana",
      "Tripura",
      "Uttarakhand",
      "Uttar Pradesh",
      "West Bengal",
      "Andaman and Nicobar Islands",
      "Chandigarh",
      "Dadra and Nagar Haveli",
      "Daman and Diu",
      "Delhi",
      "Lakshadweep",
      "Puducherry",
    ],
  },
  mutations: {
    setTotalCourses(state,val){
      state.total.courses = val
    },
    setTotalSubjects(state,val){
      state.total.subjects = val
    },
    setUserProfile(state, val ) {
      state.userProfile = val;
    },
    editUserProfile(state,val){
      state.editUserProfile = val;
    },
    setCourses(state, val) {
      state.courses = val;
    },
    setSubject(state, val) {
      state.subjects = val;
    },
    editStudent(state,val){
      state.editStudentDetail = val
    },
    students(state,val){
      state.students = val
    },
    viewStudent(state,val){
      state.viewStudent = val
    }
  },
  actions: {
    async signup({ dispatch }, form) {
      const { user } = await fb.auth.createUserWithEmailAndPassword(
        form.email,
        form.password
      );
      await fb.userCollection.doc(user.uid).set({
        name: form.name,
        gender: form.gender,
        mobilenumber: form.mobilenumber,
        email: form.email,
      });
      dispatch("fetchUserProfile", user);
    },
    async fetchUserProfile({ commit }, user) {
      await fb.userCollection.doc(user.uid).onSnapshot((doc) => {
        commit('editUserProfile',doc.data())
        commit('setUserProfile',doc.data())
      });
      if (router.currentRoute.path === "/login") {
        router.push("/");
      }
    },
    async login({ dispatch }, form) {
      const { user } = await fb.auth.signInWithEmailAndPassword(
        form.email,
        form.password
      );
      dispatch("fetchUserProfile", user);
    },
    async logout({ commit }) {
      await fb.auth.signOut();
      commit("setUserProfile", {});
      router.push("/login");
    }
  },
  plugins: [createPersistedState()]
});


fb.courseCollection.onSnapshot((Snapshot) => {
  let categoryArray = [];
  Snapshot.forEach((doc) => {
    let category = doc.data();
    category.id = doc.id;
    categoryArray.push(category);
  });
  const totalCourses = categoryArray.length
  store.commit("setCourses", categoryArray);
  store.commit('setTotalCourses',totalCourses)
});

fb.subjectCollection.onSnapshot((Snapshot) => {
  let subjectArray = [];
  Snapshot.forEach((doc) => {
    let subject = doc.data();
    subject.id = doc.id;
    subjectArray.push(subject);
  });
  store.commit("setSubject", subjectArray);
  const totalSubjcts = subjectArray.length
  store.commit('setTotalSubjects',totalSubjcts)
});

fb.studentCollection.orderBy('timestamp','desc').onSnapshot((Snapshot)=>{
  let studentArray = [];
  Snapshot.forEach(doc=>{
    let student = doc.data()
    student.id = doc.id
    studentArray.push(student)
  })
  store.commit('students',studentArray)
})


export default store;
