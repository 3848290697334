<template>
    <b-navbar>
        
        <template #start>
            <span class="text-4xl font-semibold mt-4 pl-16">{{ $route.name }}</span>    
        </template>


        <template #end>
            
        <b-dropdown :triggers="['hover']" aria-role="list" position="is-bottom-left" class="mr-5">
            <template #trigger>
                <div class="flex mr-8 mt-2.5">
                     <img src="../assets/images/others.png" v-if="userProfile.gender == 'Others'" class="rounded-full mr-2 mt-2.5 w-8 h-8 ">
                    <img src="../assets/images/female.png" v-else-if="userProfile.gender == 'Female'" class="rounded-full mr-2 mt-2.5 w-8 h-8 ">
                    <img src="../assets/images/man.png" v-else class="rounded-full mr-2 mt-2.5 w-8 h-8 ">
                    <span class="text-md font-semibold pt-3.5">{{userProfile.name}}</span>
                </div>  
            </template>

            <b-dropdown-item aria-role="listitem">
                <router-link to="/profile">
                 <i class="fas fa-user pr-4"/>Profile
                </router-link>
            </b-dropdown-item>

            <b-dropdown-item aria-role="listitem">
                <router-link to="/changePassword">
                    <i class="fas fa-key pr-4"/>Change Password
                </router-link> 
            </b-dropdown-item>


            <b-dropdown-item aria-role="listitem" @click="logout" ><i class="fas fa-sign-out-alt pr-4"/>Sign Out</b-dropdown-item>
        </b-dropdown>
        </template>


    </b-navbar>
</template>

<script>
import {mapState} from 'vuex'
    export default {
        name:'NavBar',
        data() {
            return {
                
            }
        },
        computed:{
            ...mapState(['userProfile'])
        },
        methods: {
            logout(){
                this.$store.dispatch('logout')
            }
        },

    }
</script>

<style scoped>

</style>